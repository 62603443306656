import React from "react"

const GenesisCustody = () => {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M70 0H0V70H70V0Z" fill="#1848F9" />
            <path
                d="M43.9683 39.0089C44.2133 39.0089 44.3883 39.0089 44.5633 39.0089C46.5206 39.0089 48.4752 39.017 50.4325 39.0008C50.8041 38.9981 50.9144 39.1058 50.9117 39.4854C50.8929 41.8224 50.8929 44.1593 50.9117 46.4962C50.9144 46.8785 50.7852 47.1208 50.4971 47.3524C47.1452 50.0447 43.3679 51.7112 39.071 52.177C35.7567 52.5351 32.526 52.2335 29.4379 50.9627C24.4275 48.9004 21.2021 45.2147 19.7671 39.9889C18.9379 36.9735 18.8625 33.9178 19.611 30.8835C20.8629 25.8058 23.8002 22.0016 28.466 19.592C31.7371 17.9039 35.2291 17.4597 38.8691 17.772C41.0983 17.9631 43.2414 18.4208 45.2767 19.3551C46.806 20.0578 48.1764 21.0028 49.4714 22.0662C49.641 22.2062 49.7217 22.3247 49.5494 22.532C48.1414 24.2201 46.7387 25.9162 45.3333 27.6097C45.2902 27.6608 45.2364 27.7012 45.1556 27.7793C44.8998 27.5801 44.6414 27.3916 44.3937 27.1897C42.1106 25.3185 39.4937 24.3574 36.5429 24.387C32.7494 24.4247 29.9791 26.2231 28.116 29.462C26.7321 31.8716 26.4548 34.4831 26.9098 37.1889C27.3783 39.9781 28.6975 42.2639 31.0237 43.9143C32.6633 45.0774 34.5183 45.6293 36.5241 45.6804C38.6752 45.737 40.7375 45.3735 42.676 44.3935C43.8714 43.7878 43.8767 43.7985 43.8767 42.4739C43.8767 41.5235 43.8552 40.5704 43.8875 39.6227C43.9037 39.1947 43.7556 39.1058 43.3571 39.1085C41.3325 39.1274 39.3079 39.1058 37.286 39.1247C36.8821 39.1274 36.7475 39.0224 36.7529 38.6024C36.7744 36.5778 36.7691 34.5531 36.7556 32.5312C36.7529 32.1731 36.8633 32.0628 37.2214 32.0654C39.3267 32.0789 41.4321 32.0789 43.5375 32.0654C43.8714 32.0628 43.9844 32.1678 43.9817 32.507C43.9683 34.4912 43.9764 36.4727 43.9764 38.457C43.9683 38.6185 43.9683 38.7801 43.9683 39.0089Z"
                fill="white"
            />
        </svg>
    )
}

export default GenesisCustody
