import React from "react"
import NormalPageHero from "../components/NormalPageHero"
import WalletHelmet from "../components/SEO/WalletHelmet"
import Layout from "../templates/Layout"

import * as styles from "../styles/wallets/wallets.module.css"
import OTC from "../sections/wallets/InstitutionalCustodians"
import NewsletterForm from "../components/NewsletterForm"

import LatestUpdatesBackground from "../assets/LatestUpdatesBackground.png"
import OfficialWallets from "../sections/wallets/OfficialWallets"
import ThirdPartyWallets from "../sections/wallets/ThirdPartyWallets"

const Wallets = () => {
    return (
        <Layout>
            <WalletHelmet />
            <div className="bg-horizen-hp-bg">
                <div
                    className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.walletsHero}`}
                >
                    <NormalPageHero
                        titleKey="wallets.hero.heading"
                        subtitleKey="wallets.hero.p1"
                    />
                </div>
                <OfficialWallets />
                <div className="pt-10">
                    <ThirdPartyWallets />
                </div>
                <div className="pt-20">
                    <OTC />
                </div>
                <div
                    className="bg-contain"
                    style={{
                        backgroundImage: `url(${LatestUpdatesBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="pt-32">
                        <NewsletterForm />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Wallets
