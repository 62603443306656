import React from "react"

type Links = {
    links: {
        label: string
        link: string
    }[]
}
const DownloadLinks = ({ links }: Links) => {
    return (
        <div>
            <ul>
                {links.map((link) => {
                    return (
                        <li>
                            <a href={link.link}>{link.label}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default DownloadLinks
