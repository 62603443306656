import React from "react"
import { useIntl } from "gatsby-plugin-intl"

type Table = {
    tableHeader: string[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableRows: any
    gridCols: string
}

const Table = ({ tableHeader, tableRows, gridCols }: Table) => {
    const { formatMessage } = useIntl()
    const getBorderClasses = (index, length) => {
        switch (index) {
            case 0:
                return "lg:border-l"
                break
            case length - 1:
                return "lg:border-r"
                break
            default:
                return ""
        }
    }

    const getPaddingClasses = (index, length) => {
        if (index !== length - 1 && index !== 0) {
            return "pr-6"
        }
        return ""
    }

    return (
        <>
            <div className={`grid grid-cols-2 ${gridCols} items-center pb-7`}>
                <div className="hidden lg:block border border-b-0 h-[23px] col-span-full" />
                {tableHeader.map((tableHeaderItem, index) => {
                    return (
                        <div
                            className={`hidden lg:block font-bold text-horizen-grey tracking-[0.2em] text-xs mb-6 ${getPaddingClasses(
                                index,
                                tableHeader.length
                            )}`}
                        >
                            {tableHeaderItem !== "wallets.table.header.icon" &&
                                formatMessage({
                                    id: tableHeaderItem,
                                }).toUpperCase()}
                        </div>
                    )
                })}
                {tableRows.map((tableRow) => {
                    return tableRow.map((item, index) => {
                        return (
                            <>
                                {index === 0 && (
                                    <div className="lg:hidden border-t col-span-full" />
                                )}
                                <div
                                    className={`${
                                        index === 0 ? "hidden" : ""
                                    } ${
                                        index === 1 ? "mt-6" : ""
                                    } lg:hidden font-bold text-horizen-grey tracking-[0.2em] text-xs mb-6 ${getPaddingClasses(
                                        index,
                                        tableHeader.length
                                    )}`}
                                >
                                    {formatMessage({
                                        id: tableHeader[index],
                                    }).toUpperCase()}
                                </div>
                                <div
                                    className={`${
                                        index === 0 ? "hidden lg:flex" : ""
                                    } ${
                                        index === 1 ? "mt-6 lg:mt-0" : ""
                                    } lg:border-t lg:border-b lg:h-40 flex items-center text-horizen-content-grey mb-6 ${getBorderClasses(
                                        index,
                                        tableRow.length
                                    )} ${getPaddingClasses(
                                        index,
                                        tableRow.length
                                    )}`}
                                >
                                    {item}
                                </div>
                            </>
                        )
                    })
                })}
                <div className="lg:hidden border-b col-span-full" />
            </div>
        </>
    )
}

export default Table
