export const tableHeader = [
    "wallets.table.header.icon",
    "wallets.table.header.wallet",
    "wallets.table.header.type",
    "wallets.table.header.transparentAddressMultisig",
    "wallets.table.header.memoField",
    "wallets.table.header.userControlsPrivateKeys",
    "wallets.table.header.download",
]

export const gridCols = `lg:grid-cols-[114px,minmax(150px,1.25fr),repeat(5,1fr)]`
