import React from "react"

interface CrossIconProps {
    redVariant?: boolean
}

const CrossIcon: React.FC<CrossIconProps> = ({ redVariant }) => {
    return redVariant ? (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[20px]"
        >
            <line
                x1="19"
                y1="1.41421"
                x2="2.41421"
                y2="18"
                stroke="#FF0000"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <line
                x1="18.5858"
                y1="18"
                x2="2"
                y2="1.41421"
                stroke="#FF0000"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    ) : (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.4">
                <path
                    d="M16 2L2 16"
                    stroke="#C8CFD5"
                    strokeWidth="2"
                    strokeLinecap="square"
                />
                <path
                    d="M2 2L16 16"
                    stroke="#C8CFD5"
                    strokeWidth="2"
                    strokeLinecap="square"
                />
            </g>
        </svg>
    )
}

export default CrossIcon
