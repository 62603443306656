import React, { useEffect, useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import {
    gridCols,
    tableHeader,
} from "../../components/Wallets/Tables/tableConsts"

import CheckIcon from "../../components/Wallets/Tables/CheckIcon"
import ContentContainer from "../../templates/ContentContainer"
import DownloadLinks from "../../components/Wallets/Tables/DownloadLinks"
import HorizenEmblem from "../../assets/Wallets/TableLogos/HorizenEmblem"
import SectionHeading from "../../components/SectionHeading"
import Table from "../../components/Table"
import CrossIcon from "../../components/Wallets/Tables/CrossIcon"
import cobaltLogo from "../../assets/BackgroundImages/WalletsCobalt/cobalt-logo.svg"

const OfficialWallets = () => {
    const { formatMessage } = useIntl()

    const [releaseTagName, setReleaseTagName] = useState("")
    const [windowsDownloadLink, setWindowsDownloadLink] = useState("")
    const [macDownloadLink, setMacDownloadLink] = useState("")
    const [linuxLink, setLinuxLink] = useState("")

    const getSphereLatestReleaseData = async () => {
        const result = await fetch(
            "https://api.github.com/repos/HorizenOfficial/Sphere_by_Horizen/releases"
        )
            .then((response) => response.json())
            .then((data) => data[0])
        return result
    }

    const getReleaseLink = (assets, extension) => {
        const release = assets.find((asset) => asset.name.endsWith(extension))
        return release.browser_download_url
    }

    const getWindowsDownloadLink = (assets) => getReleaseLink(assets, ".exe")
    const getMacDownloadLink = (assets) => getReleaseLink(assets, ".dmg")

    useEffect(() => {
        const updateLatestReleaseData = async () => {
            const sphereLatestReleaseData = await getSphereLatestReleaseData()
            setReleaseTagName(sphereLatestReleaseData.tag_name)
            setWindowsDownloadLink(
                getWindowsDownloadLink(sphereLatestReleaseData.assets)
            )
            setMacDownloadLink(
                getMacDownloadLink(sphereLatestReleaseData.assets)
            )
            setLinuxLink(sphereLatestReleaseData.html_url)
        }

        updateLatestReleaseData()
    })

    const OfficialWalletTitle = ({ name, releaseTag = "" }) => {
        return (
            <div>
                <span className="font-bold text-[22px] block">
                    {formatMessage({
                        id: `wallets.officialWallets.${name}.title`,
                    })}
                </span>
                <span className="text-horizen-content-grey">{releaseTag}</span>
            </div>
        )
    }

    const tableRows = [
        [
            <HorizenEmblem />,
            <OfficialWalletTitle name="sphere" releaseTag={releaseTagName} />,
            "Desktop",
            <CheckIcon />,
            <CheckIcon />,
            <CheckIcon />,
            <DownloadLinks
                links={[
                    {
                        label: "Windows",
                        link: windowsDownloadLink,
                    },
                    {
                        label: "Mac",
                        link: macDownloadLink,
                    },
                    {
                        label: "Linux",
                        link: linuxLink,
                    },
                ]}
            />,
        ],
        [
            <div className="flex justify-center items-center w-full">
                <img src={cobaltLogo} alt="cobalt logo" className="h-[46px]" />
            </div>,
            <OfficialWalletTitle name="cobalt" />,
            "Browser Extension",
            <CrossIcon />,
            <CrossIcon />,
            <CheckIcon />,
            <DownloadLinks
                links={[
                    {
                        label: "Chrome Web Store",
                        link: "https://chromewebstore.google.com/detail/cobalt/hekbjgfncacdinlajhgiakpaieajpfph",
                    },
                ]}
            />,
        ],
    ]

    return (
        <ContentContainer>
            <div className="text-center">
                <SectionHeading>
                    {formatMessage({
                        id: "wallets.officialWallets.sectionHeading",
                    })}
                </SectionHeading>
            </div>
            <div className="pt-12">
                <Table
                    tableHeader={tableHeader}
                    tableRows={tableRows}
                    gridCols={gridCols}
                />
            </div>
        </ContentContainer>
    )
}

export default OfficialWallets
