import React from "react"

interface CheckIconProps {
    greenVariant?: boolean
}

const CheckIcon: React.FC<CheckIconProps> = ({ greenVariant }) => {
    return greenVariant ? (
        <svg
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[20px]"
        >
            <path
                d="M2 10.3076L7.67238 16L22 2"
                stroke="#26DB8D"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    ) : (
        <svg
            width="24"
            height="18"
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 10.3076L7.67238 16L22 2"
                stroke="#0E9DE5"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    )
}

export default CheckIcon
