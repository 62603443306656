import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import BCBitcoin from "../../assets/Wallets/InstitutionalCustodians/BCBitcoin"
import BoxWithLinearGradient from "../../components/BoxWithLinearGradient"
import CoinbaseCustodySVG from "../../assets/Wallets/InstitutionalCustodians/CoinbaseCustody"
import ContentContainer from "../../templates/ContentContainer"
import Copper from "../../assets/Wallets/InstitutionalCustodians/Copper"
import GenesisCustody from "../../assets/Wallets/InstitutionalCustodians/GenesisCustody"
import SectionHeading from "../../components/SectionHeading"

type InstitutionalCustodiansBox = {
    title: string
    href: string
    img: React.ReactElement
}

const InstitutionalCustodiansBox = ({
    title,
    href,
    img,
}: InstitutionalCustodiansBox) => {
    return (
        <BoxWithLinearGradient>
            <a className="flex flex-col h-full justify-between" href={href}>
                <div className="self-center pt-12">{img}</div>
                <span className="pb-4 font-bold text-center">{title}</span>
            </a>
        </BoxWithLinearGradient>
    )
}

const InstitutionalCustodians = () => {
    const institutionalCustodiansKeys = ["coinbaseCustody", "bcBitcoin"]
    const institutionalCustodiansImages = {
        coinbaseCustody: CoinbaseCustodySVG,
        copper: Copper,
        genesisCustody: GenesisCustody,
        bcBitcoin: BCBitcoin,
    }
    const { formatMessage } = useIntl()
    return (
        <ContentContainer>
            <div className="text-center">
                <SectionHeading>
                    {formatMessage({
                        id: "wallets.institutionalCustodians.sectionHeading",
                    })}
                </SectionHeading>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 auto-rows-[190px] gap-4 pt-12">
                {institutionalCustodiansKeys.length % 2 === 0 &&
                    institutionalCustodiansKeys.length % 4 !== 0 && (
                        <div className="hidden lg:block" />
                    )}
                {institutionalCustodiansKeys.map(
                    (institutionalCustodiansKey) => (
                        <InstitutionalCustodiansBox
                            title={formatMessage({
                                id: `wallets.institutionalCustodians.${institutionalCustodiansKey}.title`,
                            })}
                            href={formatMessage({
                                id: `wallets.institutionalCustodians.${institutionalCustodiansKey}.href`,
                            })}
                            img={institutionalCustodiansImages[
                                institutionalCustodiansKey
                            ]()}
                        />
                    )
                )}
            </div>
        </ContentContainer>
    )
}

export default InstitutionalCustodians
