import { Helmet } from "react-helmet"
import React from "react"

function WalletHelmet() {
    return (
        <Helmet>
            <title>ZEN Wallets - Store and Transact ZEN - Horizen</title>
            <meta
                property="og:title"
                content="ZEN Wallets - Store and Transact ZEN - Horizen"
            />
            <meta
                name="twitter:title"
                content="ZEN Wallets - Store and Transact ZEN - Horizen"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Store your ZEN on one of our wallets or the many wallets we are integrated with including hardware wallets, paper wallets, Android and iOS wallets, and more."
            />
            <meta
                property="og:description"
                content="Store your ZEN on one of our wallets or the many wallets we are integrated with including hardware wallets, paper wallets, Android and iOS wallets, and more."
            />
            <meta
                name="twitter:description"
                content="Store your ZEN on one of our wallets or the many wallets we are integrated with including hardware wallets, paper wallets, Android and iOS wallets, and more."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/wallets.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/wallets.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="zen wallet, zen cash wallet, zencash wallet, horizen wallet, zen coin wallet, crypto wallet"
            />
            <link rel="canonical" href="https://www.horizen.io/wallets/" />
            <meta property="og:url" content="https://www.horizen.io/wallets/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default WalletHelmet
