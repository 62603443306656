import React from "react"

const WalletLogo = ({ walletName }: { walletName: string }) => {
    return (
        <div className="flex justify-center items-center w-full max-w-[46px] mx-auto">
            <img
                src={`/WalletsLogos/${walletName}Logo.svg`}
                alt={`${walletName} logo`}
            />
        </div>
    )
}

export default WalletLogo
