import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import {
    gridCols,
    tableHeader,
} from "../../components/Wallets/Tables/tableConsts"

import CheckIcon from "../../components/Wallets/Tables/CheckIcon"
import ContentContainer from "../../templates/ContentContainer"
import CrossIcon from "../../components/Wallets/Tables/CrossIcon"
import DownloadLinks from "../../components/Wallets/Tables/DownloadLinks"
import SectionHeading from "../../components/SectionHeading"
import Table from "../../components/Table"
import WalletLogo from "../../components/Wallets/Tables/WalletLogo"

const ThirdPartyWallets = () => {
    const { formatMessage } = useIntl()

    const wallets = formatMessage({
        id: "wallets.thirdPartyWallets.wallets",
    }).split(",")

    const tableRows = []

    const getTypesComponent = (wallet) => {
        const types = formatMessage({
            id: `wallets.thirdPartyWallets.wallets.${wallet}.type`,
        }).split(",")
        return (
            <div>
                <ul>
                    {types.map((type) => (
                        <li>{type}</li>
                    ))}
                </ul>
            </div>
        )
    }

    const getCheckOrCrossIcon = (option) => {
        const optionDecoded = formatMessage({ id: option })
        if (optionDecoded === "true") {
            return <CheckIcon />
        }
        return <CrossIcon />
    }

    const getLinksComponent = (walletName) => {
        const platformsDecoded = formatMessage({
            id: `wallets.thirdPartyWallets.wallets.${walletName}.downloadPlatforms`,
        }).split(",")
        const links = []
        platformsDecoded.forEach((platform) => {
            links.push({
                label: formatMessage({
                    id: `wallets.thirdPartyWallets.platforms.${platform}`,
                }),
                link: formatMessage({
                    id: `wallets.thirdPartyWallets.wallets.${walletName}.downloadPlatforms.${platform}.link`,
                }),
            })
        })
        return <DownloadLinks links={links} />
    }

    wallets.forEach((wallet) => {
        tableRows.push([
            <WalletLogo walletName={wallet} />,
            <div className="font-bold text-[22px]">
                {formatMessage({
                    id: `wallets.thirdPartyWallets.wallets.${wallet}.name`,
                })}
            </div>,
            getTypesComponent(wallet),
            getCheckOrCrossIcon(
                `wallets.thirdPartyWallets.wallets.${wallet}.transparentAddressMultisig`
            ),
            getCheckOrCrossIcon(
                `wallets.thirdPartyWallets.wallets.${wallet}.memoField`
            ),
            getCheckOrCrossIcon(
                `wallets.thirdPartyWallets.wallets.${wallet}.userControlsPrivateKeys`
            ),
            getLinksComponent(wallet),
        ])
    })

    return (
        <ContentContainer>
            <SectionHeading>
                {formatMessage({
                    id: "wallets.thirdPartyWallets.sectionHeading",
                })}
            </SectionHeading>
            <div className="pt-12">
                <Table
                    tableHeader={tableHeader}
                    tableRows={tableRows}
                    gridCols={gridCols}
                />
            </div>
        </ContentContainer>
    )
}

export default ThirdPartyWallets
