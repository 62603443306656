import React from "react"

function CoinbaseCustody() {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M70 0H0V70H70V0Z" fill="#1D58EC" />
            <path
                d="M35.0002 55.4884C29.5267 55.4884 24.3817 53.3561 20.5129 49.4872C16.644 45.6184 14.5117 40.4734 14.5117 34.9999C14.5117 29.5265 16.644 24.3815 20.5129 20.5126C24.3817 16.6438 29.5267 14.5115 35.0002 14.5115C40.4736 14.5115 45.6186 16.6438 49.4875 20.5126L43.1686 26.8315C40.9879 24.6507 38.0856 23.4472 35.0002 23.4472C31.9148 23.4472 29.0152 24.648 26.8317 26.8315C24.6483 29.0149 23.4502 31.9146 23.4502 34.9999C23.4502 38.0853 24.6509 40.9849 26.8344 43.1684C29.0179 45.3519 31.9148 46.5499 35.0002 46.5499C38.0856 46.5499 40.9852 45.3492 43.1686 43.1657L49.4875 49.4845C45.6186 53.3561 40.4736 55.4884 35.0002 55.4884Z"
                fill="white"
            />
        </svg>
    )
}

export default CoinbaseCustody
