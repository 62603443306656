import React from "react"

const HorizenEmblem = () => {
    return (
        <div className="flex justify-center items-center w-full">
            <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M41.7 9.61499L37.935 15.445C39.085 17.715 39.74 20.28 39.74 23C39.74 32.245 32.245 39.74 23 39.74C20.285 39.74 17.72 39.09 15.455 37.94L9.625 41.705C13.395 44.405 18.01 46 23 46C35.705 46 46 35.705 46 23C46 18.005 44.405 13.385 41.7 9.61499Z"
                    fill="url(#paint0_radial_1519_497)"
                />
                <path
                    d="M22.9999 34.52C28.6599 34.52 33.3699 30.445 34.3599 25.07C32.5699 24.685 30.7099 24.48 28.8049 24.48C23.0649 24.48 17.7549 26.315 13.4199 29.42C15.4899 32.495 19.0099 34.52 22.9999 34.52Z"
                    fill="url(#paint1_radial_1519_497)"
                />
                <path
                    d="M28.695 22.375C30.705 22.375 32.665 22.595 34.555 23.005V23C34.555 16.62 29.385 11.45 23.005 11.45C16.625 11.45 11.455 16.62 11.455 23C11.455 23.525 11.495 24.04 11.56 24.545C11.56 24.545 11.56 24.545 11.555 24.545C8.645 26.755 7.265 28.505 7.225 28.56C6.61 26.82 6.27 24.95 6.27 22.995C6.27 13.75 13.765 6.255 23.01 6.255C25.715 6.255 28.27 6.9 30.535 8.045L36.365 4.275C32.59 1.59 27.98 0 23 0C10.295 0 0 10.295 0 23C0 28.115 1.67 32.835 4.495 36.65C5.585 34.68 6.91 32.86 8.43 31.225C8.43 31.225 10.7 28.86 12.435 27.67C16.995 24.345 22.615 22.375 28.695 22.375Z"
                    fill="white"
                />
                <defs>
                    <radialGradient
                        id="paint0_radial_1519_497"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(41.7013 9.58719) scale(38.6957)"
                    >
                        <stop offset="0.0986" stopColor="#56BC7A" />
                        <stop offset="0.191" stopColor="#54B882" />
                        <stop offset="0.3603" stopColor="#4FB294" />
                        <stop offset="0.5876" stopColor="#46A8AF" />
                        <stop offset="0.8618" stopColor="#369AD0" />
                        <stop offset="0.886" stopColor="#3298D4" />
                    </radialGradient>
                    <radialGradient
                        id="paint1_radial_1519_497"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(41.7012 9.58717) scale(38.6957)"
                    >
                        <stop offset="0.0986" stopColor="#56BC7A" />
                        <stop offset="0.191" stopColor="#54B882" />
                        <stop offset="0.3603" stopColor="#4FB294" />
                        <stop offset="0.5876" stopColor="#46A8AF" />
                        <stop offset="0.8618" stopColor="#369AD0" />
                        <stop offset="0.886" stopColor="#3298D4" />
                    </radialGradient>
                </defs>
            </svg>
        </div>
    )
}

export default HorizenEmblem
